.img-cover-store {
  border-radius: 0.6rem 0.6rem 0 0;
  object-fit: cover;
}

@keyframes click-animation {
  from {
    width: 180px;
  }
  to {
    width: 220px;
  }
}

.input-search {
  margin-left: -15px;
  width: 180px;
  border-radius: 12px !important;
  &-icon {
    margin-left: -30px;
    z-index: 2;
  }
  &:focus {
    width: 220px;
    animation: click-animation 0.2s linear 1;
  }
}

.banner {
  // margin-top: -10vw;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.35) 100%);
  height: 750px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10vw 0px;

  .container {
    margin-top: 20px;

    .catalog-logo {
      background-color: white;
      border: 1px solid black;
      width: 120px;
      height: 120px;
      object-fit: cover;
    }

    .catalog-name,
    .catalog-uname {
      color: white;
      font-weight: 600;
    }

    .catalog-uname {
      font-size: 2em;
    }

    .font-profile {
      font-size: 14px;
    }

    .catalog-blur {
      width: fit-content;
      backdrop-filter: blur(12px);
      border-radius: 26px;
      background-color: rgba(0, 0, 0, 0.3);
      max-height: 120px;
    }

    .profile-overflow {
      max-height: 95pt;
      overflow-y: auto;
    }
    // SCROLLBAR
    .profile-overflow::-webkit-scrollbar {
      width: 5pt;
    }

    .profile-overflow::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px transparent;
    }

    .profile-overflow::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.397);
      border-radius: 14%;
    }

    .catalog-link {
      &:hover {
        background-color: white;
        .catalog-name {
          color: #000;
        }
      }
    }
    .catalog-link2 {
      color: black;
      background-color: white;
      opacity: 0.8;
      .catalog-name {
        color: black;
      }
      &:hover {
        background-color: black;
        .catalog-name {
          color: white;
        }
      }
    }
  }
}

.bg-banner {
  margin-top: -10vw;
  display: grid;
  gap: 0 !important;
  height: 750px;
  grid-auto-rows: 20vw;
  grid-template-columns: repeat(5, 1fr);
  overflow: hidden;

  .banner-items {
    height: 100%;
  }

  .banner-image-grid {
    object-fit: cover;
  }

  .banner-color,
  .banner-image-grid {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .banner {
    padding: 10vw 20px;
    height: 550px;
    overflow: hidden;
    .container {
      margin-top: 50px !important;
      .catalog-uname {
        font-size: 2em !important;
      }
      .catalog-logo {
        width: 80px;
        height: 80px;
      }
      .profile-overflow {
        max-height: 120pt;
      }
    }
  }

  .icon-copy {
    .rui-icon {
      width: 20px !important;
      min-width: 20px !important;
      height: 20px !important;
    }
  }

  .bg-banner {
    height: 550px;
    grid-auto-rows: 33.3vw !important;
    grid-template-columns: repeat(auto-fit, minmax(33.3vw, 0fr));
  }

  .border-zinc {
    display: none;
  }
}