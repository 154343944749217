@import '../../../../assets/css/variables';
@import '../../../../assets/css/rootui-parts/sign';

@keyframes click-animation {
  from {
    width: 180px;
  }
  to {
    width: 220px;
  }
}

.wrapper__konten {
  .icon__support {
    border-radius: 50%;
    padding: 0.8rem;
    font-size: 2.5rem;
    svg {
      width: 2rem;
      height: 1.5rem;
    }
  }
  .number__support {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-color: #e7f1ff;
    color: #245cd3;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
}
.input__number {
  width: 40px;
  padding: 0.8rem;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.support_card input,
.support-message,
//  this will impact to global validation too if activate the code below 
// .invalid-feedback,
.input__number,
.support-alert {
  font-family: Open Sans, sans-serif !important;
  color: #343a40 !important;
}
.b-12 {
  border-radius: 12px !important;
}
.b-12-top {
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}
a.linktree__link--button {
  .icon_emoji_style {
    position: absolute;
    margin-top: -35px !important;
    font-size: 40px !important;
  }
  .icon_image_style {
    position: absolute;
    margin-top: -20px !important;
  }
}
.linktree__link--button.btn {
  text-transform: none !important;
  font-size: 1rem !important;
  line-height: 1.8 !important;
  .icon_emoji_style {
    position: absolute;
    margin-top: -24px !important;
    font-size: 40px !important;
  }
  .icon_image_style {
    position: absolute;
    margin-top: -9px !important;
  }
}
.one_click_edit {
  p {
    color: #393f49 !important;
  }
}

.tag-catalog {
  font-size: 1rem;
  border-radius: 5px;
  padding: 0.75em 1em;
}

.dropdown-menu-tags {
  position: absolute;
  z-index: 1;
  padding: 9px 15px;
  max-width: 400px;
  left: 0;
  right: 0;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.catalog-tags {
  overflow-x: auto;

  .chosen-tag {
    background: #007bff !important;
  }

  .chosen-tag-text {
    color: #fff !important;
  }

  &-text {
    font-size: 1rem;
  }
}

.linktree__modal .checkout-actions {
  display: block !important;
}

iframe {
  height: 400px;
}

.input-search {
  margin-left: -15px;
  width: 180px;
  border-radius: 12px !important;
  &-icon {
    margin-left: -30px;
    z-index: 2;
  }
  &:focus {
    width: 220px;
    animation: click-animation 0.2s linear 1;
  }
}

.dropdown-menu-product {
  position: absolute;
  z-index: 1;
  padding: 9px 15px;
  max-width: 400px;
  left: 0;
  right: 0;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.text-capitalize { text-transform: capitalize !important; }
